export const numberToCurrency = (value: number, decimals = 0): string => {
  const optionsCurrency: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }

  return new Intl.NumberFormat('pt-BR', optionsCurrency).format(value)
}

export const removingTagsHtml = (value: string): string => {
  return value.replace(/<\/?[^>]+(>|$)/g, '')
}

export const formatTextRich = (value: string): string[] => {
  if (!value) {
    return []
  }

  const values = removingTagsHtml(value).split('\n')
  return values.filter(t => t !== '' && t !== '\r')
}
