import { useStore } from 'lib/store'
import { IconEmptyVacancies } from 'components'
import { ButtonApplyTalentBank } from '../ButtonApplyTalentBank'

export const ListVacanciesEmpty = () => {
  const { user, filter } = useStore()

  const emptyFilter = !filter.title && !filter.locations

  return (
    <div className="flex items-center gap-x-12 m-auto py-6">
      <div className="flex flex-col gap-y-4 md:gap-y-2 text-center md:text-start">
        <h1 className="leading-9 md:leading-tight text-lg md:text-xl text-gray-800 max-w-full md:max-w-[390px] font-bold">
          {emptyFilter
            ? 'Ainda não há vagas abertas'
            : 'Ops! Vaga não encontrada'}
        </h1>

        <div className="block md:hidden mx-auto">
          <IconEmptyVacancies className="w-[268px] h-auto" />
        </div>

        {emptyFilter ? (
          <>
            <p className="text-gray-600 text-subtitle1 md:text-xs max-w-[480px] mt-2">
              {user?.appliedTalentBank
                ? 'Você já faz parte do banco de talentos desta empresa! No momento, não há vagas cadastradas, mas novas oportunidades podem surgir a qualquer momento. Volte sempre para conferir as novidades.'
                : 'Esta empresa ainda não tem vagas abertas, mas você pode se cadastrar no banco de talentos e estar pronto para a próxima decolagem!'}
            </p>

            {!user?.appliedTalentBank && <ButtonApplyTalentBank />}
          </>
        ) : (
          <p className="text-gray-600 text-subtitle1 md:text-xs max-w-[420px] mt-2">
            Parece que não encontramos vagas que correspondam aos filtros
            aplicados. Experimente ajustar os filtros para explorar uma galáxia
            de oportunidades!
          </p>
        )}
      </div>

      <div className="hidden md:block">
        <IconEmptyVacancies />
      </div>
    </div>
  )
}
