import { Testimonials } from '../Testimonials'
import { AboutCompany } from './about-company'
import { Photos } from './photos'
import { VideoPlayer } from './video-player'

export const Company = () => (
  <div className="flex flex-col gap-12">
    <section className="flex flex-col items-center gap-y-8">
      <AboutCompany />
      <VideoPlayer />
    </section>

    <Testimonials />
    <Photos />
  </div>
)
