import Image from 'next/image'
import { useMemo } from 'react'
import { useStore } from 'lib/store'
import { Icon, Modal } from 'components'

const makeThumbnail = (url, fixed = true, type = 'youtube') => {
  if (!url) return ''

  if (type === 'youtube') {
    const code = url
      .replace('https://www.youtube.com/watch?v=', '')
      .replace('https://www.youtube.com/embed/', '')
      .replace('https://youtu.be/', '')

    if (code) {
      return fixed
        ? `https://img.youtube.com/vi/${code}/0.jpg`
        : `https://www.youtube.com/embed/${code}`
    }
  }

  return ''
}

export const VideoPlayer = () => {
  const company = useStore(store => store.company)

  const videoYoutube = useMemo(() => {
    if (!company?.urlVideo) return ''

    const videoId = company.urlVideo.includes('v=')
      ? company.urlVideo.split('v=')[1].split('&')[0]
      : company.urlVideo.split('/').pop().split('?')[0]

    return `https://www.youtube.com/embed/${videoId}`
  }, [company.urlVideo])

  if (!videoYoutube) {
    return <></>
  }

  return (
    <Modal
      className="p-0"
      size="1080px"
      trigger={
        <button
          type="button"
          className="relative w-full max-w-[600px] h-[210px] md:h-[335px] rounded-lg overflow-hidden group flex shadow-md"
        >
          <Image
            src={makeThumbnail(videoYoutube)}
            fill
            alt="Executar vídeo"
            className="transition-all duration-500 group-hover:scale-110 object-cover"
            loading="lazy"
            quality={100}
            sizes="600px"
          />
          <div className="absolute inset-0 bg-black bg-opacity-10 group-hover:bg-opacity-50 pointer-events-none transition-all duration-500" />
          <Icon
            size="xl"
            face="play_circle"
            className="m-auto text-white scale-150 opacity-60 group-hover:opacity-100 z-10 transition-all duration-500"
          />
        </button>
      }
    >
      <iframe
        src={videoYoutube}
        title="YouTube video player"
        className="w-full h-[350px] lg:h-[620px] rounded-lg"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      />
    </Modal>
  )
}
