import classNames from 'clsx'

const optionsClass =
  'absolute top-14 z-10 bg-white shadow-md rounded-sm w-full overflow-y-auto scroll transition-all duration-500'

const options = (open: boolean) =>
  classNames(optionsClass, {
    'max-h-60': open,
    'max-h-0': !open
  })

export default { options }
