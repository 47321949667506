import classNames from 'clsx'
import { useStore } from 'lib/store'
import { TestimonialCard } from './card'
import { useRef, useState } from 'react'

const Carrousel = () => {
  const company = useStore(state => state.company)
  const testimonials = company.testimonials

  const isSimple = testimonials.length <= 2
  const contentRef = useRef<HTMLUListElement>()
  const [count, setCount] = useState<number>(0)

  const handleChangeTestimonial = (newPosition: number): void => {
    setCount(newPosition)

    if (!isSimple) {
      const id = 'card-testimonial-' + testimonials[newPosition]?.id
      const sizeCard = document.getElementById(id)?.clientWidth
      contentRef.current.scrollLeft = sizeCard * newPosition
    }
  }

  if (isSimple) {
    return (
      <ul className="w-full flex flex-col lg:flex-row gap-y-3 justify-between">
        {testimonials.map(testimonial => (
          <li className="w-full lg:w-[49%]" key={testimonial.name}>
            <TestimonialCard {...testimonial} />
          </li>
        ))}
      </ul>
    )
  }

  return (
    <div className="space-y-5 block w-full px-0 sm:px-4">
      <ul
        ref={contentRef}
        className="w-full flex gap-x-4 overflow-hidden snap-x scroll-smooth"
      >
        {testimonials.map(testimonial => (
          <li
            key={'card-' + testimonial.name}
            id={`card-testimonial-${testimonial.id}`}
            className="w-full lg:w-[49%] flex-none snap-start p-1.5"
          >
            <TestimonialCard {...testimonial} />
          </li>
        ))}
      </ul>

      <div className="flex items-center justify-center gap-x-2 last:[&>button]:flex lg:last:[&>button]:hidden">
        {testimonials.map((testimonial, index) => (
          <button
            type="button"
            key={'button' + testimonial.name}
            className="flex w-3 h-3"
            onClick={() => handleChangeTestimonial(index)}
          >
            <span
              className={classNames(
                'm-auto rounded-full transition-all duration-300 w-full h-full',
                count === index && company.color.background,
                count !== index && 'scale-75 bg-black/20'
              )}
            />
          </button>
        ))}
      </div>
    </div>
  )
}

export const Testimonials = () => {
  const company = useStore(state => state.company)
  const testimonials = company?.testimonials || []

  if (testimonials.length === 0) {
    return <></>
  }

  return (
    <section className="flex flex-col items-center gap-y-4 w-full mb-4">
      <h3 className="text-sm md:text-md font-semibold">
        O que dizem sobre nossa empresa
      </h3>
      <Carrousel />
    </section>
  )
}
