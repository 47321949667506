import { ButtonApplyTalentBank } from '../ButtonApplyTalentBank'

export const BannerTalentBank = () => (
  <section
    id="banco-de-talentos"
    className="bg-[#212B36] text-white flex flex-col gap-3 md:gap-2 items-center p-10 text-center mt-20"
  >
    <h3 className="text-md md:text-lg leading-tight font-bold">
      Cadastre seu currículo no banco de talentos
    </h3>
    <p className="text-body2 md:text-subtitle1 max-w-[680px]">
      Não encontrou a vaga desejada? Cadastre em nosso banco de talentos e
      aumente as suas chances de uma candidatura em nossos processos seletivos.
    </p>

    <ButtonApplyTalentBank />
  </section>
)
