import classNames from 'clsx'
import { useState } from 'react'
import { Icon } from 'components'
import { useStore } from 'lib/store'
import OutsideClickHandler from 'react-outside-click-handler'

import styles from './styles'
import { useQuery } from '@tanstack/react-query'
import { getCitiesInVacancies } from 'services/vacancy'

interface Props {
  value: string
  onChange: (value: string) => void
}

export const InputLocation = ({ value, onChange }: Props) => {
  const company = useStore(store => store.company)
  const [openOptions, setOpenOptions] = useState(false)

  const { data: locations } = useQuery({
    queryKey: ['cities-in-vacancies', company.slug],
    queryFn: () => getCitiesInVacancies(company.slug),
    enabled: !!company.slug
  })

  const handleOnChange = (location: string) => {
    onChange(location === 'Todos' ? '' : location)
    setOpenOptions(false)
  }

  return (
    <div className="w-full">
      <OutsideClickHandler onOutsideClick={() => setOpenOptions(false)}>
        <label className="text-body2" htmlFor="search-vacancy-local">
          Local
        </label>

        <div className="relative mt-1">
          <button
            onClick={() => setOpenOptions(state => !state)}
            className="flex items-center gap-3 w-full bg-transparent transition-all duration-300 px-4 py-2.5 rounded-lg border border-gray-500/[.32] text-gray-800 hover:border-gray-800"
          >
            <Icon
              face="location_on"
              variant="outlined"
              className="text-gray-500"
              size="md"
            />
            <span
              className={classNames(
                'px-4 py-1 rounded-full bg-opacity-75 text-caption',
                company.color.background,
                company.color.text
              )}
            >
              {value || 'Todos'}
            </span>
            <Icon
              face="keyboard_arrow_down"
              className={classNames(
                'text-gray-500 ml-auto transition-all duration-300',
                openOptions && 'rotate-180'
              )}
              size="md"
            />
          </button>

          <div className={styles.options(openOptions)}>
            <div className="flex flex-col gap-1 p-4 ">
              {locations &&
                locations.map(location => (
                  <button
                    type="button"
                    key={location}
                    onClick={() => handleOnChange(location)}
                    disabled={value === location}
                    className="px-1.5 py-2 rounded text-body1 text-gray-800 text-left transition-all duration-300 hover:bg-gray-200 disabled:bg-gray-200 disabled:opacity-70 disabled:text-gray-600"
                  >
                    {location}
                  </button>
                ))}
            </div>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  )
}
