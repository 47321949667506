import { useEffect, useState } from 'react'
import { Button, Icon, IconProfilerGroup, ModalAction } from 'components'

import env from 'lib/env'
import { useStore } from 'lib/store'
import { useToast } from 'hooks/useToast'
import { handleToAuth } from 'services/user'
import { cookies, redirect } from 'lib/cookies'
import { applyTalentBankInCompany } from 'services/company'

type ModalType =
  | 'review-curriculum'
  | 'without-profiler'
  | 'success-apply-talent-bank'
  | 'complete-required-fields'

export const ButtonApplyTalentBank = () => {
  const toast = useToast()
  const { company, user, isLoadingUser, updateStoreUser } = useStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [modal, setModal] = useState<ModalType>(null)

  const setCookiesApplyTalentBank = () => {
    const tabKey = cookies.get('redirectCurrentWindow') || ''
    const tab = JSON.parse(cookies.get(tabKey) || '{}')
    const payload = {
      ...tab,
      company: {
        referenceId: company.referenceId,
        slug: company.slug,
        name: company.name
      },
      applyingTalentBase: true,
      origin: ''
    }

    cookies.set(window.name, JSON.stringify(payload))
    cookies.set(
      'applying-talent-base',
      JSON.stringify({
        applyingTalentBase: true,
        applied: false,
        slug: company.slug,
        referenceId: company.referenceId
      })
    )
  }

  const onRefreshTalentBank = async () => {
    setCookiesApplyTalentBank()
    return redirect(env.host_profile.concat('/curriculo'))
  }

  const onApplyTalentBank = async () => {
    setIsLoading(true)

    try {
      await applyTalentBankInCompany(company)
      updateStoreUser({ appliedTalentBank: true })

      setModal('success-apply-talent-bank')
    } catch (err) {
      console.log(err)

      const status = err?.response?.status

      if (status === 404) {
        setModal('without-profiler')
        return
      } else if (status === 400) {
        setModal('complete-required-fields')
        return
      }

      toast.error({ title: 'Falha ao aplicar ao banco de talentos!' })
    } finally {
      setIsLoading(false)
      cookies.remove('applying-talent-base')
    }
  }

  const handleApply = () => {
    if (!user) {
      handleToAuth()
      return
    }

    setModal('review-curriculum')
  }

  const handleCompleteProfiler = async () => {
    setCookiesApplyTalentBank()
    return redirect(env.host_profile.concat('/pre-profiler'))
  }

  useEffect(() => {
    const continueTalentBankRegister = () => {
      try {
        const applyingTalentBaseKey = cookies.get('applying-talent-base')
        if (!applyingTalentBaseKey) return

        const { applyingTalentBase } = JSON.parse(applyingTalentBaseKey)

        if (company?.referenceId && applyingTalentBase) {
          onApplyTalentBank()
        }
      } catch (error) {
        console.error('Erro ao processar applying-talent-base:', error)
      }
    }

    if (company?.referenceId) {
      continueTalentBankRegister()
    }
  }, [company])

  return (
    <>
      <ModalAction
        icon="edit"
        open={modal === 'review-curriculum'}
        iconVariant="round"
        title="Gostaria de revisar o seu currículo?"
        description="Ao enviar o seu currículo, seu cadastro será realizado imediatamente"
        cancelButton={{
          action: onRefreshTalentBank,
          label: 'Revisar'
        }}
        confirmButton={{
          action: onApplyTalentBank,
          label: 'Enviar',
          loading: isLoading
        }}
      />

      <ModalAction
        size="400px"
        icon={<IconProfilerGroup />}
        open={modal === 'without-profiler'}
        title="Complete o Profiler para continuar"
        description="Para fazer parte do banco de talentos desta empresa, é necessário preencher o Profiler. É rápido e simples!"
        confirmButton={{
          action: () => handleCompleteProfiler(),
          label: 'Fazer agora'
        }}
        cancelButton={{ action: () => setModal(null), label: 'Agora não' }}
      />

      <ModalAction
        icon="check_circle"
        iconVariant="round"
        open={modal === 'success-apply-talent-bank'}
        title="Sucesso!"
        description={
          <>
            Seu cadastro no banco de talentos da {company.name} foi realizado
            com <br /> sucesso! <br /> Entraremos em contato caso surja uma nova
            oportunidade compatível com as suas competências.
          </>
        }
        confirmButton={{
          action: () => setModal(null),
          label: 'Ok, entendi!'
        }}
        className="[&>span]:text-success"
      />

      <ModalAction
        icon="offline_bolt"
        iconVariant="round"
        open={modal === 'complete-required-fields'}
        title="Você está a alguns passos da sua candidatura"
        description="Para concluir sua candidatura você deverá revisar seu currículo e preencher os campos obrigatórios exigidos pela vaga. Deseja revisar seu currículo?"
        cancelButton={{ action: () => setModal(null) }}
        confirmButton={{
          action: onRefreshTalentBank,
          label: 'Revisar',
          loading: isLoading
        }}
      />

      {user?.appliedTalentBank ? (
        <div className="flex items-center justify-center gap-1.5 rounded-lg px-6 mt-6 bg-white/10 h-10 text-white">
          <Icon face="task_alt" />
          <span className="text-body2">Seu currículo já está cadastrado!</span>
        </div>
      ) : (
        <Button
          onClick={handleApply}
          loading={isLoadingUser || isLoading}
          colorCompany
          className="mt-6 py-2.5"
        >
          Cadastre seu currículo
        </Button>
      )}
    </>
  )
}
