import { useStore } from 'lib/store'
import Image from 'next/image'

export const Photos = () => {
  const company = useStore(store => store.company)
  let photos = company?.photos || []

  photos = photos.filter(photo => photo !== null)

  if (photos.length === 0) {
    return <></>
  } else if (photos.length === 1) {
    return (
      <section className="flex justify-center w-full">
        <img
          src={photos[0]}
          className="w-auto h-[260px] md:h-[520px] shadow-md rounded-md"
        />
      </section>
    )
  }

  return (
    <section className="flex flex-col md:grid grid-cols-5 grid-rows-2 gap-4 rounded-xl overflow-hidden [&>span]:h-[200px] md:[&>span]:h-auto">
      {photos.map(photo => (
        <span
          key={photo}
          className="first:row-span-2 first:col-span-3 col-span-2 group md:first:h-[420px] xl:first:h-[520px] relative shadow-md overflow-hidden"
        >
          <Image
            fill
            src={photo}
            alt="Imagem da empresa"
            className="transition-all duration-500 group-hover:scale-110 object-cover"
            loading="lazy"
            quality={100}
            sizes="760px"
          />
        </span>
      ))}
    </section>
  )
}
