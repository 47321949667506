import { numberToCurrency } from 'utils/format'
import { VacancySalary, VacancyAddress, Vacancy } from 'types/vacancy'

export const formatAddress = (address: VacancyAddress) => {
  if (!address) {
    return { long: '', short: '' }
  }

  const { country, state, city } = address

  let cityAndState = city?.name
  let short = country?.name || 'Local não informado'

  if (cityAndState || state) {
    cityAndState += cityAndState ? ' - '.concat(state.code) : state.code

    if (cityAndState) {
      short = cityAndState
    }
  }

  const fields = [
    address.street_address,
    address.number,
    address.additional_information,
    address.neighborhood,
    cityAndState,
    country?.name,
    address.zip_code
  ]

  const long = fields.filter(field => field).join(', ')

  return { long, short }
}

export const formatSalary = (salary: VacancySalary): string => {
  if (salary) {
    const { finalRange, initialRange, type } = salary
    const final = finalRange ? numberToCurrency(finalRange) : ''
    const initial = initialRange ? numberToCurrency(initialRange) : ''

    if (type === 'simple' && final) {
      return final
    } else if (type === 'range' && initial) {
      return initial === final ? initial : `${initial} - ${final}`
    }
  }

  return 'Não informado'
}

export const buildVacancyToPage = (data): Vacancy => {
  return {
    id: data.id,
    title: data.title,
    description: data.description,
    currentState: data.currentState,
    referenceId: data.referenceId,
    address: formatAddress(data.address),
    jobType: data.jobType,
    salary: formatSalary(data.salary),
    hasSpecialNeeds: data.hasSpecialNeeds,
    peopleWithDisabilities: data.peopleWithDisabilities,
    pcdOnly: data.pcdOnly,
    seniority: data.seniority,
    recruitmentContractType: data.recruitmentContractType,
    benefits: data.benefits,
    language: data.language,
    hardSkills: data.hardSkills,
    education: data.education,
    availablePositions: data.availablePositions,
    affirmative: data.affirmative,
    isGoogleForJobsEnabled: data.isGoogleForJobsEnabled,
    requiredFields: data.requiredFields,
    type: data.type,
    occupationAreas: data.occupationAreas,
    createdAt: data.createdAt,
    showModality: data.showModality,
    applied: !!data?.applied,
    shift: !!data?.shift?.length ? data.shift[0].name : '',
    receivingResume: data?.receivingResume
  }
}

const getNamesInList = list => list.map(item => item.name).toString()

export const buildVacancyToGoogleForJobs = vacancy => {
  if (!vacancy?.isGoogleForJobsEnabled) {
    return null
  }

  const data = {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    title: vacancy.title,
    description: vacancy.description,
    datePosted: vacancy.createdAt,
    identifier: {
      '@type': 'PropertyValue',
      name: 'Portal de Vagas Sólides',
      value: 'vagas.solides.com.br'
    },
    hiringOrganization: {
      '@type': 'Organization',
      name: vacancy.companyName,
      sameAs: vacancy.redirectLink,
      logo: vacancy.companyLogo
    },
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        addressLocality: vacancy.city?.name,
        addressRegion: vacancy.state?.code,
        addressCountry: vacancy.state?.country || 'BR',
        streetAddress: vacancy.city?.streetAddress || vacancy.city?.name || ''
      }
    },
    baseSalary:
      vacancy.salary?.initialRange &&
      vacancy.salary?.finalRange &&
      vacancy.salary?.showRangeToApplicant
        ? {
            '@type': 'MonetaryAmount',
            currency: vacancy.salary.currency || 'BRL',
            value: {
              '@type': 'QuantitativeValue',
              minValue: vacancy.salary.initialRange,
              maxValue: vacancy.salary.finalRange,
              unitText: vacancy.salary.frequency || 'MONTH'
            }
          }
        : '',
    // eslint-disable-next-line prettier/prettier
    employmentType: vacancy.showModality ? getNamesInList(vacancy.recruitmentContractType) : '',
    jobBenefits: getNamesInList(vacancy.benefits),
    educationRequirements: getNamesInList(vacancy.education),
    experienceRequirements: getNamesInList(vacancy.hardSkills)
  }

  return Object.keys(data).reduce((acc, key) => {
    const field = data[key]
    if (field) {
      acc[key] = field
    }
    return acc
  }, {})
}
