import classNames from 'clsx'
import { useRouter } from 'next/router'
import { useQuery } from '@tanstack/react-query'
import { Pagination } from '@solides/management-util-ui'

import { useStore } from 'lib/store'
import { getVacancies } from 'services/vacancy'
import { buildVacancyToPage } from 'utils/vacancy'

import { VacancyCard } from 'components'
import { ListVacanciesLoading } from './loading'
import { ListVacanciesEmpty } from './empty-state'

export const ListVacancies = () => {
  const router = useRouter()
  const { filter, updateStoreFilter, company } = useStore()

  const {
    data: list,
    isLoading,
    isFetching
  } = useQuery({
    queryKey: ['vacancies', filter, company.slug],
    queryFn: () => getVacancies(filter, company.slug),
    enabled: !!company.slug
  })

  if (!list || isLoading || isFetching) {
    return <ListVacanciesLoading />
  } else if (list.data.length === 0) {
    return <ListVacanciesEmpty />
  }

  const handlePagination = (page: number) => {
    const query = updateStoreFilter({ page })
    router.push('/?' + query)
  }

  const smallList =
    !filter.title &&
    !filter.locations &&
    list.currentPage === 1 &&
    list.data.length <= 6
  const maxRange = list.currentPage * 12

  return (
    <div className="w-full flex flex-col gap-y-8">
      <div className="mt-4 mb-2">
        <h1 className="text-md text-gray-800 font-bold">Vagas</h1>

        <p className="text-body2 text-gray-500">
          Mostrando {(list.currentPage - 1) * 12 || 1} -{' '}
          {list.count < maxRange ? list.count : maxRange} de{' '}
          <strong>{list.count} vagas encontradas</strong>
        </p>

        <hr className="border-0 border-t-[1px] border-t-gray-500/25 mt-[18px]" />
      </div>

      <section
        className={classNames(
          'grid grid-cols-1 md:grid-cols-2 gap-6',
          !smallList && 'xl:grid-cols-3'
        )}
      >
        {list.data.map(vacancy => (
          <VacancyCard
            key={vacancy.id + vacancy.title}
            {...buildVacancyToPage(vacancy)}
          />
        ))}
      </section>

      {list.totalPages > 1 && (
        <Pagination
          pageSize={12}
          totalCount={list.count}
          pageNumber={list.currentPage}
          onChangePagination={page => handlePagination(page)}
        />
      )}
    </div>
  )
}
