import { Box } from 'components'

export const ListVacanciesLoading = () => (
  <div className="w-full flex flex-col gap-y-8">
    <div className="mt-4 mb-2">
      <h1 className="text-md text-gray-800 font-bold">Vagas</h1>
      <div className="h-4 bg-slate-200 rounded w-48 sm:w-80" />

      <hr className="border-0 border-t-[1px] border-t-gray-500/25 mt-[18px]" />
    </div>

    <section className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(item => (
        <Box
          key={item}
          className="animate-pulse flex flex-col gap-4 p-4 shadow-sm"
          role="listitem"
        >
          <div className="h-5 bg-slate-200 rounded w-48 sm:w-80" />

          <div className="flex gap-2">
            <div className="h-3.5 bg-slate-200 rounded w-[144px]" />
            <div className="h-3.5 bg-slate-200 rounded w-[144px]" />
          </div>

          <div className="flex gap-2">
            {[1, 2, 3, 4].map(badge => (
              <div
                key={badge + 'b' + item}
                className="h-5 bg-slate-200 rounded w-full"
              />
            ))}
          </div>

          <div className="h-8 bg-slate-200 rounded w-full mt-1.5" />
        </Box>
      ))}
    </section>
  </div>
)
