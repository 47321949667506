/* eslint-disable no-irregular-whitespace */
import React from 'react'
import { useStore } from 'lib/store'
import {
  Box,
  IconFacebookF,
  IconInstagram,
  IconLinkedin,
  IconYoutube,
  IconGlassdoor
} from 'components'

const icons = {
  linkedin: <IconLinkedin />,
  facebook: <IconFacebookF />,
  instagram: <IconInstagram />,
  youtube: <IconYoutube />,
  glassdoor: <IconGlassdoor />
}

export const AboutCompany = () => {
  const company = useStore(store => store.company)
  const description =
    company?.description ||
    'Temos diversos vagas de emprego, aguardando sua candidatura.'

  const hasExternalLink = company?.social?.length > 0 || company?.companyUrl
  const changeDefaultTitle = !company?.description && hasExternalLink

  return (
    <Box id="quem-somos" className="flex-1">
      <div className="flex flex-col gap-y-2">
        <h4 className="font-semibold text-sm">
          {changeDefaultTitle ? 'Nos Acompanhe' : 'Quem Somos'}
        </h4>

        <div
          className="break-words text-gray-700"
          style={{ whiteSpace: 'pre-wrap' }}
        >
          <span
            className="flex flex-col gap-4 [&>ul]:list-disc [&>ul]:whitespace-normal [&>ol]:whitespace-normal whitespace-pre-wrap [&>ul]:ml-6 [&>ol]:ml-6 [&>ol]:list-decimal"
            dangerouslySetInnerHTML={{
              __html: description.replace(/<p> <\/p>/g, '')
            }}
          />
        </div>

        {hasExternalLink && (
          <div className="flex flex-wrap items-center gap-x-2 sm:gap-x-2.5 mt-4">
            {Object.keys(icons).map((key: string) => {
              const social = company.social.find(s => s.type === key)

              if (social?.url && social.url.includes('https')) {
                return (
                  <a
                    key={key}
                    href={social.url}
                    target="_blank"
                    rel="noreferrer"
                    className="[&>svg>path]:fill-gray-700 opacity-70 hover:opacity-100 hover:-translate-y-1 transition-all duration-500"
                    aria-label={`Link para rede social ${key} da empresa ${company.name}`}
                  >
                    {icons[social.type]}
                  </a>
                )
              }

              return null
            })}

            {company.companyUrl && (
              <a
                target="_blank"
                rel="noreferrer"
                href={company.companyUrl}
                className="text-[#1890ff] ml-auto hover:underline"
                aria-label={`Link para site da empresa ${company.name}`}
              >
                Acessar site da empresa
              </a>
            )}
          </div>
        )}
      </div>
    </Box>
  )
}
